import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ISearchModule } from '@/store/modules/search/ISearchModule';
import StoreModules from '@/store/StoreModules';
import store from '@/store';

@Module({
  dynamic: true,
  name: StoreModules.Search,
  namespaced: true,
  store,
})
class SearchModule extends VuexModule implements ISearchModule {
  showSearch = false;

  @Mutation
  public toggleSearchState() {
    this.showSearch = !this.showSearch;
  }
}

export default getModule(SearchModule);

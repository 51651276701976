import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import IModeModule from '@/store/modules/mode/IModeModule';
import StoreModules from '@/store/StoreModules';
import store from '@/store';
@Module({
    dynamic: true,
    name: StoreModules.Mode,
    namespaced: true,
    store,
  })
  class ModeModule extends VuexModule implements IModeModule{
    mode = ''
    value = -1;
    popupDialogOpen = false;
    globalShadow = false;

    @Mutation
    public toggleMode(modeValue:string) {
      this.mode = modeValue;
    }
    
    @Mutation
    public toggleShadow(status:boolean) {
      this.globalShadow = status;
    }

    @Mutation
    public disablePopUp(value: number = null) {
      this.popupDialogOpen = false;
    }

    @Mutation
    public setPopUpStatus(value: number = null) {
      this.popupDialogOpen = !this.popupDialogOpen;
    }
    @Mutation
    public setValue(value: number)
    {
      if(value)
      {
        this.value = value
      }
    }
    
  }
  
  export default getModule(ModeModule);
import { getModule, Module, Action, VuexModule, Mutation } from 'vuex-module-decorators';
import StoreModules from '@/store/StoreModules';
import store from '@/store';

import { IUsersModule, UsersModuleActions as Actions } from '@/store/modules/users/IUserModule';
import { REQUEST_DEBOUNCE_TIME } from '@/services/helpers/constants';
import { Role, SubRole, UserDataInfo } from '@/typings/domain';
import { RolesModuleActions } from '../roles/IRolesModule';
import UserInfo from '@/models/dto/responses/user.response.type';

import RolesModule from '../roles/RolesModule';
import self from './UsersModule'; // HACK: mutations are not accesable from another mutations
import ApiClient from '@/api/api.client';
import throttle from '@/utils/throttle';
import UserData from '@/components/user/UserDataContainer.vue';

// const updateUser = throttle(function(user: UserInfo) {
//   ApiClient.user.saveEditedUser(user).then(r => console.log(r));
// }, 600);


@Module({
  dynamic: true,
  name: StoreModules.Users,
  namespaced: true,
  store,
})
class UsersModule extends VuexModule implements IUsersModule {
  currentUserId: number | null = null;
  permissionKnowledge: boolean;
  owner = {} as {id: number, avatar: string, firstName: string};
  users: Map<number, UserInfo> = new Map();
  requestPending: Map<number, number> = new Map();

  constructor(props: unknown) {
    super(props);

    // store.subscribeAction(action => {
    //   const { type, payload } = action;

    //   if (`${StoreModules.Roles}/${RolesModuleActions.deleteSubRole}` === type) {
    //     this.onSpecializationDelete(payload);
    //   }
    //   if (`${StoreModules.Roles}/${RolesModuleActions.deleteRole}` === type) {
    //     this.onRoleDelete(payload);
    //   }
    // });
  }

  /**
   * @returns user object
   */
  get getById() {
    return (id: number): UserInfo => self.users.get(id);
  }

 
  @Mutation
  setOwner(userOwner: {id: number, first_name: string, avatar: string}) 
  { 
    this.owner.id = userOwner.id;
    this.owner.firstName = userOwner.first_name;
    this.owner.avatar = userOwner.avatar;
  };

  @Mutation
  updateUserProfile(user: UserDataInfo) {
    ApiClient.user.saveEditedUser(user).then(r => console.log(r));
  };
  /**
   * @returns current user object
   */
  get currentUser(): UserInfo | null {
    if (this.currentUserId === null) return null;
    const currentUser = self.users.get(this.currentUserId);

    const userHasAdminSpesialisation = currentUser.specialisations.filter((el) => el.is_admin === true)

    if(!currentUser.admin && userHasAdminSpesialisation.length)
    {
      currentUser.admin = true;
    }
    return self.users.get(this.currentUserId);
  }

  /**
   * @returns user's specialisations
   */
  get getSpecializations() {
    return async (user: number | UserInfo): Promise<Array<SubRole>> => {
      const isId = typeof user === 'number';
      const { roles } = isId ? await this.getById(user as number) : (user as UserInfo);
      let specialisations: Array<SubRole> = [];
      if(roles)
      {
        roles.forEach(role => {
          specialisations = [...specialisations, ...role.specialisations];
        });
      }

      return specialisations;
    };
  }
    /**
   * @returns users' ids
   */
  get getPermissionKnowledge()
  {
    return this.permissionKnowledge;
  }
  /**
   * @returns users' ids
   */
  get usersByRole() {
    return (roleId: number): Array<number> => {
      const ids: Array<number> = [];

      self.users.forEach(user => {
        if (this.hasRole(user, roleId)) ids.push(user.id);
      });

      return ids;
    };
  }



  /**
   * @returns users' ids
   */
  get usersBySpecializationId() {
    return (specializationId: number): Array<number> => {
      const ids: Array<number> = [];

      self.users.forEach(user => {
        if (this.hasSpecialization(user, specializationId)) ids.push(user.id);
      });

      return ids;
    };
  }

  get ownerUser()
  {
    return this.owner;
  }

  /**
   * @returns user specializations IDs
   */
  get getSpecializationsIds() {
    return (userId: number) => {
      const ids: Array<number> = [];
      const user = this.getById(userId);
      if(user.roles)
      {
        user.roles.forEach(role => {
          role.specialisations.forEach(specialisations => {
            ids.push(specialisations.id);
          });
        });
      }

      return ids;
    };
  }

  /**
   * @returns user roles IDs
   */
  get getRolesIds() {
    return (userId: number) => {
      const ids: Array<number> = [];
      const user = this.getById(userId);

      user.roles.forEach(role => {
        ids.push(role.id);
      });

      return ids;
    };
  }

  @Mutation
  setCurrentUser(id: number) {
    this.currentUserId = id;
  }

  @Mutation
  setUser(payload: { id: number; user: UserInfo }) {
    const { id, user } = payload;

    // Add mock data
    if (!this.users.has(id)) {
      user.isAdmin = true;
    }
    this.users = new Map(this.users).set(id, user);
  }

  @Mutation
  addUpdateRequest(payload: { id: number; user: UserInfo }) {
    const { id, user } = payload;

    this.requestPending.set(
      id,
      setTimeout(async () => {
        console.warn('Imitate sending to api', user);

        self.clearUpdateRequst(id);
      }, REQUEST_DEBOUNCE_TIME),
    );
  }

  @Mutation
  clearUpdateRequst(id: number) {
    if (this.requestPending.has(id)) {
      clearTimeout(this.requestPending.get(id));
      this.requestPending.delete(id);
    }
  }

  /**
   * @Action updateUser
   */
  @Action
  async [Actions.updateUser](payload: { id: number; userData: { [key: string]: unknown } }) {
    const { id, userData } = payload;
    const user = this.getById(id);
    const userPropsKeys = Object.keys(user);

    userPropsKeys.forEach((key: string) => {
      if (userData[key] !== undefined) user[key] = userData[key];
    });

    this.setUser({ id, user });

    // updateUser(user);
  }

  @Action
  async [Actions.completeRegistration](userData: UserDataInfo)
  {
    await ApiClient.user.completeRegistration(userData);
  }

  /**
   * @Action updatePassword
   */
  @Action
  [Actions.updatePassword](payload: { userId: number; password: string }) {
    const { userId, password } = payload;
    console.warn(`Password upadating implementation goes here\n${userId} : ${password}`);
  }

  /**
   * @Action fetchUserIfNotExist
   */
  @Action
  async [Actions.fetchUser](userId: number) {
    const user = await ApiClient.user.get(userId);
    const userAvatar = user.avatar;
    this.setUser({ id: userId, user });
    user.avatar = await userAvatar;
  }
  
  @Action
  async [Actions.fetchOwner]() {
    const ownerData = (await ApiClient.settings.getOwner()).data;
    this.setOwner(ownerData)
  }
  /**
   * @Action addSpecialization
   */
  @Action
  async [Actions.addSpecialization](payload: { userId: number; specializationId: number }) {
    const { userId, specializationId } = payload;
    const ids = await this.getSpecializationsIds(userId);
    const newRoles = this.prepareRoles([...ids, specializationId]);

    this.updateUser({
      id: userId,
      userData: {
        roles: newRoles,
      },
    });
  }

  /**
   * @Action removeSpecialization
   */
  @Action
  async [Actions.removeSpecialization](payload: { userId: number; specializationId: number }) {
    const { userId, specializationId } = payload;
    const ids = await this.getSpecializationsIds(userId);
    const index = ids.indexOf(specializationId);

    ids.splice(index, 1);

    const newRoles = this.prepareRoles(ids);

    this.updateUser({
      id: userId,
      userData: {
        roles: newRoles,
      },
    });
  }

  /**
   * @Action removeRole
   */
  @Action
  [Actions.removeRole](payload: { userId: number; roleId: number }) {
    const { userId, roleId } = payload;
    const { roles } = this.users.get(userId);
    if(roles)
    {
      const index = roles.findIndex(role => role.id === roleId);

      if (index !== -1) {
        roles.splice(index, 1);

        this.updateUser({
          id: userId,
          userData: {
            roles: roles,
          },
        });
      }
    }
    
  }

  // ~~~ Event handling actions ~~~

  @Action
  onSpecializationDelete(specializationId: number) {
    const users = this.usersBySpecializationId(specializationId);
    users.forEach(userId => {
      this.removeSpecialization({ userId, specializationId });
    });
  }

  @Action
  onRoleDelete(roleId: number) {
    const users = this.usersByRole(roleId);
    users.forEach(userId => {
      this.removeRole({ userId, roleId });
    });
  }

  // ~~~ Helpers ~~~

  get hasRole() {
    return async (user: number | UserInfo, roleId: number) => {
      const isId = typeof user === 'number';
      const { roles } = isId ? await this.getById(user as number) : (user as UserInfo);
      return roles.findIndex(role => role.id === roleId) !== -1;
    };
  }

  get hasSpecialization() {
    return async (user: number | UserInfo, specializationId: number) => {
      const specialisations = await this.getSpecializations(user);
      return specialisations.findIndex(specialisations => specialisations.id === specializationId) !== -1;
    };
  }

  get prepareRoles() {
    return (specializationsIds: Array<number>): Array<Role> => {
      const allRoles = RolesModule.actualRoles;
      const preparedRoles: Array<Role> = [];

      allRoles.forEach(role => {
        role.specialisations.forEach(specialisations => {
          if (specializationsIds.includes(specialisations.id)) {
            const roleAdded = preparedRoles.findIndex(prepared => prepared.id === role.id) !== -1;

            if (!roleAdded) preparedRoles.push({ ...role, specialisations: [] });

            preparedRoles.find(prepared => prepared.id === role.id).specialisations.push(specialisations);
          }
        });
      });

      return preparedRoles;
    };
  }
}

export default getModule(UsersModule);

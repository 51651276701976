export enum DocumentModes {
  Create = 'create',
  Edit = 'edit',
  View = 'view',
}

export enum DocumentTypes {
  Post = 'post',
  Link = 'link',
}

export interface DocumentTypesTitles {
  [DocumentTypes.Post]: string;
  [DocumentTypes.Link]: string;
}

export interface DocumentName {
  title: string;
  subtitle?: string;
}

export interface PulseDocument extends DocumentName {
  content: object;
}

import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import ROUTES from '@/router/ROUTES';
import shouldBeAuthenticated from '@/router/middlewares/shouldBeAuthenticated';
import shouldBeAdmin from '@/router/middlewares/shouldBeAdmin';
import shouldBeGuest from '@/router/middlewares/shouldBeGuest';
import { documentsRoutes } from '@/modules/documents/routing';
import { settingsRoutes } from '@/modules/settings/router';
import { DocumentModes } from '@/modules/documents/components/document/model';
import shouldBeHashAllowed from './middlewares/shouldBeHashAllowed';
import shouldBeAllowed from './middlewares/shouldBeAllowed';
import should from './middlewares/should';

Vue.use(VueRouter);

const routes: any = [
  {
    path: ROUTES.HOME,
    redirect: ROUTES.DOCUMENTS,
    beforeEnter: shouldBeAuthenticated,
  },
  ...documentsRoutes,
  ...settingsRoutes,
  // {
  //   path: ROUTES.MARKS,
  //   name: 'Marks.Title',
  //   component: () => import('../modules/marks/Marks.vue'),
  //   beforeEnter: shouldBeAuthenticated,
  // },
  // {
  //   path: '/projects/create',
  //   name: 'Project Create',
  //   component: () => import('../modules/projects/CreateClient/NewProject.vue'),
  //   beforeEnter: shouldBeAuthenticated,
  // },
  // {
  //   path: '/projects/edit/:id',
  //   name: 'Project Edit',
  //   component: () => import('../modules/projects/EditProject/EditProject.vue'),
  //   beforeEnter: shouldBeAuthenticated,
  // },
  // {
  //   path: '/projects/:id',
  //   name: 'Project View',
  //   component: () => import('../modules/projects/ClientProject.vue'),
  //   beforeEnter: shouldBeAuthenticated,
  // },
  // {
  //   path: '/projects/team/:id',
  //   name: 'ProjectTeam View',
  //   component: () => import('../modules/projects/components/ProjectTeam.vue'),
  //   beforeEnter: shouldBeAuthenticated,
  // },
  // {
  //   path: '/projects/client/:id',
  //   name: 'ProjectClient View',
  //   component: () => import('../modules/projects/ClientDetail/ClientDetail.vue'),
  //   beforeEnter: shouldBeAuthenticated,
  // },
  // {
  //   path: '/projects/client/edit/:id',
  //   name: 'ProjectClient Edit',
  //   component: () => import('../modules/projects/EditClient/EditClient.vue'),
  //   beforeEnter: shouldBeAuthenticated,
  // },
  // {
  //   path: '/projects/knowledge',
  //   component: () => import(/* webpackChunkName: "documents-module" */ '@/modules/documents/components/document/Document.vue'),
  //   beforeEnter: shouldBeAuthenticated,
  //   children: [
  //     {
  //       path: '',
  //       redirect: 'dashboard',
  //     },
  //     {
  //       path: 'dashboard',
  //       component: () =>
  //         import(/* webpackChunkName: "documents-module" */ '../modules/documents/components/documents-dashboard/DocumentsDashboard.vue'),
  //       name: 'Documents.Title',
  //     },
  //     {
  //       path: 'create',
  //       component: () => import(/* webpackChunkName: "documents-module" */ '@/modules/documents/components/document/Document.vue'),
  //       name: 'Create Project document',
  //       props: {
  //         mode: DocumentModes.Create,
  //       },
  //     },
      
  //     {
  //       path: ':documentId',
  //       component: () => import(/* webpackChunkName: "documents-module" */ '@/modules/documents/components/document/Document.vue'),
  //       name: 'Project Document',
  //     },
  //   ],
  // },
  {
    path: ROUTES.TASKS,
    name: 'Tasks.Title',
    component: () => import('../modules/tasks/Tasks.vue'),
    beforeEnter: shouldBeAuthenticated,
  },
  {
    path: ROUTES.TEAM,
    name: 'Team.Title',
    component: () => import('../modules/team/Team.vue'),
    beforeEnter: shouldBeAuthenticated,
  },
  {
    path: '/team/user/:id',
    name: 'Team User View',
    component: () => import('../modules/user/User.vue'),
    beforeEnter: shouldBeAuthenticated,
  },
  {
    path: '/team/user/:id/edit',
    name: 'Team User Edit',
    component: () => import('../modules/user/UserEdit.vue'),
    beforeEnter: shouldBeAdmin,
  },
  {
    path: ROUTES.INVITE,
    name: 'Invite.Title',
    component: () => import('../modules/invite/Invite.vue'),
    beforeEnter: shouldBeAllowed,
    
  },
  {
    name: 'Invite Accept',
    path: '/complete/:inviteToken',
    component: () => import('../modules/invite/InviteAccept.vue'),
    beforeEnter: shouldBeAuthenticated,
  },
  {
    path: ROUTES.PROJECTS,
    name: 'Projects.Title',
    component: () => import('../modules/projects/Projects.vue'),
    beforeEnter: shouldBeAuthenticated,
  },
  {
    path: ROUTES.LOGIN,
    name: 'Login.Title',
    meta: {
      withoutNavigation: true,
    },
    beforeEnter: shouldBeGuest,
    component: () => import('../modules/login/Login.vue'),
  },
  {
    path: ROUTES.RECOVERY,
    name: 'Recovery.Title',
    meta: {
      withoutNavigation: true,
    },
    beforeEnter: shouldBeGuest,
    component: () => import('../modules/recovery/Recovery.vue'),
  },
  {
    path: ROUTES.RESTORE,
    name: 'Recovery Restore',
    meta: {
      withoutNavigation: true,
    },
    beforeEnter: shouldBeGuest,
    component: () => import('../modules/recovery/Reset.vue'),
  },
  {
    path: ROUTES.REG,
    name: 'Registration.Title',
    meta: {
      withoutNavigation: true,
    },
    beforeEnter: shouldBeGuest,
    component: () => import('../modules/registration/Registration.vue'),
  },
  {
    path: '/complete/',
    name: 'Registration Complete',
    meta: {
      withoutNavigation: true,
    },
    beforeEnter: shouldBeHashAllowed,
    component: () => import('../modules/registration/RegistrationInvited.vue'),
  },
  {
    path: ROUTES.FIRST_ENTER,
    name: 'FirstEnter.Title',
    meta: {
      withoutNavigation: true,
    },
    beforeEnter: shouldBeGuest,
    component: () => import('../modules/first-enter/FirstEnter.vue'),
  },
  {
    path: ROUTES.FIRST_SETTINGS,
    name: 'FirstSettings.Title',
    meta: {
      withoutNavigation: true,
    },
    beforeEnter: shouldBeGuest,
    component: () => import('../modules/first-settings/FirstSettings.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

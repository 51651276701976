import createCategories from '@/api/categories/actions/createCategories.action';
import getCategory from '@/api/categories/actions/getCategory.action';
import getCategories from '@/api/categories/actions/getCategories.action';
import deleteCategory from '@/api/categories/actions/deleteCategory.action';
import updateCategory from '@/api/categories/actions/updateCategory.action';

const categories = {
    createCategories,
    getCategory,
    getCategories,
    deleteCategory,
    updateCategory,
};

export default categories;

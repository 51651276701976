import { Locales, Locale } from './model';

export const getBrowserLocale = (): Locale => {
  const navigatorLocale = (navigator?.languages ? navigator?.languages[0] : navigator?.language) || null;
  let locale: Locale = null;
  if (navigatorLocale) {
    (Object.keys(Locales) as Array<Locale>).forEach(item => {
      if (navigatorLocale.indexOf(item) === 0) {
        locale = item;
      }
    });
  }
  return locale;
};

export const getInitialLocale = (): Locale => {
  return getBrowserLocale();
};

// import { Locale } from '@/services/i18n';
import { KnowledgeItem } from '@/typings/domain';

export interface IKnowledgeModule {
  	data: KnowledgeItem[]
}

export enum KnowledgeModuleActions {
  fetchKnowledge = 'fetchKnowledge',
  deleteKnowledge = 'deleteKnowledge'
}

import { Route } from 'vue-router';
import ROUTES from '@/router/ROUTES';
import Cookies from 'js-cookie';
import { HEADERS } from '@/services/helpers/constants';
import SettingsModule from '@/store/modules/settings/SettingsModule';

async function shouldBeSectionsEnabled(to: Route, from: Route, next: Function) {;
  const aToken = Cookies.get(HEADERS.AUTH_HEADER);
  if (!aToken || aToken.length === 0) {
    return next({ path: ROUTES.LOGIN });
  }
  if(!SettingsModule.getSettings.length)
  {
    await SettingsModule.fetchSettings();
  }

  if(to.path.indexOf('/documents/dashboard') > -1)
  {
    if(!SettingsModule.getSettings[SettingsModule.getSettings.findIndex((el) => el.slug === 'knowledge')])
    {
        next({path: '/team'})
    }
  }
  

  return next();
}

export default shouldBeSectionsEnabled;

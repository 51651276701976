import signup from '@/api/auth/actions/signup.action';
import me from '@/api/auth/actions/me.action';
import login from '@/api/auth/actions/login.action';
import islogged from '@/api/auth/actions/islogged.action';
import logoutAction from '@/api/auth/actions/logout.action';

const auth = {
  signup,
  me,
  login,
  islogged,
  logoutAction,
};

export default auth;

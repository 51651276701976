import { Role } from '@/typings/domain';

export interface IRolesModule {
  roles: Role[];
}

export enum RolesModuleActions {
  fetchRoles = 'fetchRoles',
  createRole = 'createRole',
  editRole = 'editRole',
  createSpecialization = 'createSpecialization',
  deleteSubRole = 'deleteSubRole',
  deleteRole = 'deleteRole',
}

import get from '@/api/invite/actions/get.action';
import send from '@/api/invite/actions/send.action';
import checkHash from '@/api/invite/actions/checkHash';
import accept from '@/api/invite/actions/accept.action';

const invite = {
  get,
  send,
  accept,
  checkHash,
};

export default invite;

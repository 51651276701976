import { RouteConfig } from 'vue-router';
import ROUTES from '@/router/ROUTES';
import shouldBeAuthenticated from '@/router/middlewares/shouldBeAuthenticated';
import shouldBeAdmin from '@/router/middlewares/shouldBeAdmin';

export const settingsRoutes: Array<RouteConfig> = [
  {
    path: ROUTES.SETTINGS,
    name: 'Settings.Title',
    component: () => import('@/modules/settings/Settings.vue'),
    beforeEnter: shouldBeAdmin,
    children: [
      {
        path: '',
        name: 'Main Settings',
        component: () => import('@/modules/settings/views/settings/Settings.vue'),
      },
      {
        path: 'payment',
        name: 'Settings.Payment',
        component: () => import('@/modules/settings/views/payment/Payment.vue'),
      },
      {
        path: 'roles-and-rights',
        name: 'Settings.Roles.Title',
        component: () => import('@/modules/settings/views/roles-and-rights/RolesAndRights.vue'),
      },
      {
        path: 'directions',
        name: 'Settings.Directions.Title',
        component: () => import('@/modules/settings/views/directions/Directions.vue'),
      },
      {
        path: 'knowledge',
        name: 'Settings.Knowledge.Title',
        component: () => import('@/modules/settings/views/knowledge/Knowledge.vue'),
      },
      {
        path: 'projects',
        name: 'Settings.Projects.Title',
        component: () => import('@/modules/settings/views/projects/Projects.vue'),
      },
      {
        path: 'teams',
        name: 'Settings.Teams.Title',
        component: () => import('@/modules/settings/views/teams/Teams.vue'),
      },
    ],
  },
];

import { Route } from 'vue-router';
import ROUTES from '@/router/ROUTES';
import islogged from '@/api/auth/actions/islogged.action';
import Cookies from 'js-cookie';
import { HEADERS } from '@/services/helpers/constants';
import GeneralModule from '@/store/modules/general/GeneralModule';
import UsersModule from '@/store/modules/users/UsersModule';
import SettingsModule from '@/store/modules/settings/SettingsModule';

async function shouldBeAuthenticated(to: Route, from: Route, next: Function) {
  const aToken = Cookies.get(HEADERS.AUTH_HEADER);
  if (!aToken || aToken.length === 0) {
    return next({ path: ROUTES.LOGIN });
  }
  await islogged().then(async (data) => 
  {
    const { auth, confirmed } = data as undefined as {auth: boolean, confirmed: boolean}
    
    if (!auth) 
    {
      Cookies.remove(HEADERS.AUTH_HEADER);
      return next({ path: ROUTES.LOGIN });
    }
    await GeneralModule.me()
    
    if(!confirmed)
    {
      return next({ path: `/team/user/${UsersModule.currentUser.id}/edit` });
    }
    if(confirmed && !SettingsModule.getSettings.length)
    {
      await SettingsModule.fetchSettings();
    }
    GeneralModule.updateAuthCompleted(confirmed);
    
  }) as undefined as { auth: boolean, confirmed: boolean };
  
  return next();
}

export default shouldBeAuthenticated;

import { Role } from '@/typings/domain';
import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';
import { ISuccessResponse } from '@/api/types';

const createRole = async (name: string, isLeader = false): Promise<ISuccessResponse<Role>> =>
{
  return axios
    .post(`${MAIN_API_HOST}/roles`, {
      name,
    },
    {
    	withCredentials: true,
    })
    .then(result => result.data);
}

export default createRole;

import setProjectSettings from '@/api/projects/actions/setProjectSettings'
import getAllClients from '@/api/projects/actions/getAllClients'
import createProjects from '@/api/projects/actions/createProjects'
import createClient from '@/api/projects/actions/createClient'
import getProject from '@/api/projects/actions/getProject'
import getAllProjects from '@/api/projects/actions/getAllProjects'
import getProjectTeam from '@/api/projects/actions/getProjectTeam'
import deleteProject from '@/api/projects/actions/deleteProject'
import editProjects from '@/api/projects/actions/editProjects'
import getProjectDirections from '@/api/projects/actions/getProjectDirections'
import getClientProjects from '@/api/projects/actions/getClientProjects'
import completeProject from '@/api/projects/actions/completeProject'
import addUsersToProject from '@/api/projects/actions/addUsersToProject'
import deleteUserFromProject from '@/api/projects/actions/deleteUserFromProject'
import getClient from '@/api/projects/actions/getClient'
import updateClient from '@/api/projects/actions/updateClient'
const projects = {
    setProjectSettings,
    getAllClients,
    getAllProjects,
    deleteUserFromProject,
    completeProject,
    addUsersToProject,
    getProjectTeam,
    getProjectDirections,
    createProjects,
    updateClient,
    editProjects,
    getClient,
    getClientProjects,
    createClient,
    deleteProject,
    getProject,
}
export default projects;
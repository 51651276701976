// TODO: remove all unused typings

import { ISelectorOption } from '@/models/inputs';
import { Rect, Vector3 } from '@/models/geometry';
import { Align } from '@/models/layout';
import { ButtonType } from '@/mixins/ButtonLike';
import { Size } from '@/mixins/Sizeable';

/** / -------------------- /
 * / --- Context menu --- /
  / ------------------- */

export enum ContextMenuItemType {
  header = 'header',
  headerInput = 'headerInput',
  divider = 'divider',
  link = 'link',
  button = 'button',
  toggle = 'toggle',
  selector = 'selector',
  gridSelector = 'grid-selector',
  input = 'input',
  search = 'search',
}

export enum ItemState {
  default = 'default',
  disabled = 'disabled',
  warning = 'warning',
  danger = 'danger',
  succsess = 'succsess',
}

export type ItemStateType = keyof typeof ItemState;

/**
 * Selector
 */
type Selector = typeof ContextMenuItemType.selector;
type GridSelector = typeof ContextMenuItemType.gridSelector;

export interface IContextMenuSelector {
  type: Selector | GridSelector;
  options: Array<ISelectorOption>;
  onChange?(select: string[] | number[]): void;
  selected?: string[] | number[];
  multiple?: boolean;
}

/**
 * Input
 */
type Input = typeof ContextMenuItemType.input;
export interface IContextMenuInput {
  type: Input;
  label?: string;
  value?: string;
  noButton?: boolean;
  iconBefore?: string;
  placeholder?: string;
  onSubmit?(value: string, node?: IContextMenuNode): void;
}

/**
 * Divider
 */
type Divider = typeof ContextMenuItemType.divider;
export interface IContextMenuDivider {
  type: Divider;
}

/**
 * Header
 */
type Header = typeof ContextMenuItemType.header;
export interface IContextMenuHeader {
  type: Header;
  text: string;
}

/**
 * Editable Header
 */
type HeaderInput = typeof ContextMenuItemType.headerInput;
export interface IContextMenuHeaderInput {
  type: HeaderInput;
  text: string;
  palceholder: string;
  onChange?(value: string): void;
}

/**
 * Header
 */
type Toggle = typeof ContextMenuItemType.toggle;
export interface IContextMenuToggle {
  type: Toggle;
  text: string;
  value?: boolean;
  onChange?(value: boolean): void;
}

/**
 * Link
 */
type Link = typeof ContextMenuItemType.link;
export interface IContextMenuLink {
  type: Link;
  text: string;
  state?: ItemStateType;
  submenu?: IContextMenuItem[];
  action?(node: IContextMenuNode): Promise<IContextMenuItem[]> | IContextMenuItem[] | void;
}

/**
 * Button
 */
type Button = typeof ContextMenuItemType.button;
export enum ButtonAligment {
  left = 'left',
  center = 'center',
  right = 'right',
}
export interface IContextMenuButton {
  type: Button;
  text: string;
  action?(node: IContextMenuNode): void;
  buttonColor?: string;
  buttonType?: ButtonType;
  buttonRounded?: boolean;
  buttonDisabled?: boolean;
  buttonSize?: Size;
  buttonAlign?: typeof ButtonAligment.left | typeof ButtonAligment.center | typeof ButtonAligment.right;
}

/**
 * Search
 */
type Search = typeof ContextMenuItemType.search;
export interface SearchItem {
  title: string;
  value: string;
}
export type SearchFunction = (search: string) => Promise<Array<SearchItem>>;
export interface IContextMenuSearch {
  type: Search;
  items: Array<SearchItem> | SearchFunction;
  onSelect?(selected: SearchItem, node: IContextMenuNode): void;
  onQueryChange?(value: string, node: IContextMenuNode): void;
}

/** / --------------- /
 * / --- Exports --- /
  / -------------- */

export type IContextMenuItem =
  | IContextMenuLink
  | IContextMenuDivider
  | IContextMenuHeader
  | IContextMenuHeaderInput
  | IContextMenuSelector
  | IContextMenuInput
  | IContextMenuToggle
  | IContextMenuButton
  | IContextMenuSearch;

export interface ContextMenusState {
  showSearch: boolean;
  menus: IContextMenuItem[];
}

export enum SpawnSide {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right',
}

export interface IContextMenuNode {
  id: number;
  $el?: HTMLElement;
  spawnerBox: Rect;
  position: Vector3;
  alignX: Align;
  alignY: Align;
  parentNode?: IContextMenuNode;
  items: Array<IContextMenuItem>;
  onSpawn?(node?: IContextMenuNode): void;
  onDestroy?(node?: IContextMenuNode): void;
  preventDestroy?(): void;
}

export interface ISurrogateNode {
  id: number;
  $el?: HTMLElement;
  items: Array<IContextMenuItem>;
  onSpawn?(node?: IContextMenuNode): void;
  onDestroy?(node?: IContextMenuNode): void;
}

export interface IContextMenus {
  menuNodes: Array<IContextMenuNode>;
}

import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';
// import UserInfo from '@/models/dto/responses/user.response.type';
import { UserDataInfo } from '@/typings/domain';

const saveEditedUser = async (request:UserDataInfo) =>
{
  const userId = request.user_id
  delete request.user_id
  return await axios.put(`${MAIN_API_HOST}/user/${userId}/edit/`, request).then(result => result.data);
}
  

export default saveEditedUser;

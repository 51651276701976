import getAllTags from '@/api/tags/actions/getAllTags.action';
import setTagName from '@/api/tags/actions/setTagName.action';
import setTagColor from '@/api/tags/actions/setTagColor.action';
import deleteTag from '@/api/tags/actions/deleteTag.action';
import createTag from '@/api/tags/actions/createTag.action';
import fetchKnowledgeTags from '@/api/tags/actions/fetchKnowledgeTags.action';

const tags = {
  getAllTags,
  setTagName,
  setTagColor,
  fetchKnowledgeTags,
  deleteTag,
  createTag,
};

export default tags;

import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';

const updateSpecialisation = async (specId: number, specialisationName?:string, is_admin?: boolean): Promise<any> =>
  axios.put(`${MAIN_API_HOST}/specialisations/${specId}`, {
    name: specialisationName,
    role_id: specId,
    is_admin,
  }).then(result => {
    const payload = result.data.data;
    return payload
  });

export default updateSpecialisation;

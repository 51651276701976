import UserInfo from '@/models/dto/responses/user.response.type';
import { ISuccessResponse } from '@/api/types';
import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';

const get = async (params: object): Promise<ISuccessResponse<UserInfo>> =>
  axios
    .get(`${MAIN_API_HOST}/complete/invation`, {
      params: params,
    })
    .then(result => result.data);

export default get;

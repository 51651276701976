import { Component, Vue } from 'vue-property-decorator';

@Component
export class ExpandableMixin extends Vue {
  public expanded = false;

  public onChange(value: boolean): void {
    this.expanded = value;
  }
}

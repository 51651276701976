import { Route } from 'vue-router';
import ROUTES from '@/router/ROUTES';
import Cookies from 'js-cookie';
import { HEADERS } from '@/services/helpers/constants';

function shouldBeGuest(to: Route, from: Route, next: Function) {
  const aToken = Cookies.get(HEADERS.AUTH_HEADER);
  
  if (!aToken || aToken.length < 2) {
    next();
  } else {
    next({
      path: ROUTES.HOME,
      replace: true,
    });
  }
}

export default shouldBeGuest;

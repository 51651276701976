enum ROUTES {
  HOME = '/',
  DOCUMENTS = '/documents',
  MARKS = '/marks',
  TASKS = '/tasks',
  TEAM = '/team',
  INVITE = '/invite',
  NEW_PROJECTS = '/new-project',
  PROJECTS_KNOWLEDGE = '/project-knowledge',
  PROJECTS = '/projects',
  NEW_PROJECT = '/projects/create',
  LOGIN = '/login',
  RECOVERY = '/password-recovery',
  RESTORE = '/restore',
  REG = '/registration',
  REG_INVITED = '/registration/invited',
  FIRST_ENTER = '/first-enter',
  SETTINGS = '/settings',
  FIRST_SETTINGS = '/first-settings',
}

export default ROUTES;

import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';
import { TagsGroup } from '@/typings/domain';

const getTagsGroups = async (): Promise<Array<TagsGroup>> =>
  axios.get(`${MAIN_API_HOST}/tag_groups`).then(result => {
    const payload = result.data.data;
    return payload;
  });

export default getTagsGroups;

import { ISuccessResponse } from '@/api/types';
import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';
import { Teams } from '@/typings/domain';

const getSpecialisationsTeam = async (params?:object): Promise<any> =>
  axios.get(`${MAIN_API_HOST}/team/specialisations/`, {
    params: params,
  }).then(result => {
    const payload = result.data.data;
    return payload;
  });

export default getSpecialisationsTeam;
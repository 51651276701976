import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';
import UserInfo from '@/models/dto/responses/user.response.type';

type AcceptAction = {
  password: string;
  hash: string;
  first_name: string;
  last_name: string;
};
const accept = async (data: AcceptAction): Promise<UserInfo> =>
  axios.post(`${MAIN_API_HOST}/complete/invation`, data).then((result) => result.data);

export default accept;

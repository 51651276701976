























































import { Component, Watch, Mixins } from 'vue-property-decorator';
import { KeyCodes, BrowserEvents } from '@/services/helpers/constants';
import { SearchbarMixin } from '@/mixins/SearchbarMixin';
import SearchModule from '@/store/modules/search/SearchModule';
import Icon from '@/components/ui/icon/Icon.vue';

@Component({
  components: {
    Icon,
  },
})
export default class SearchBar extends Mixins(SearchbarMixin) {
  public get showSearch(): boolean {
    return SearchModule.showSearch;
  }

  toggleSearch() {
    SearchModule.toggleSearchState();
  }

  /**
   * При изменении видимости поиска добавляем/убираем обработчик событий
   * закрывающий поиск при клике на другом элементе
   */
  @Watch('showSearch')
  private showSearchHandler(value: boolean): void {
    // HACK: setTimeout нужен чтобы код выполнился в следующем стэке вызовов
    setTimeout(() => {
      if (value) {
        window.addEventListener(BrowserEvents.click, this.detectClickOutside);
      } else {
        window.removeEventListener(BrowserEvents.click, this.detectClickOutside);
      }
    }, 0);
  }

  public beforeDestroy(): void {
    window.removeEventListener(BrowserEvents.click, this.detectClickOutside);
  }

  /**
   * Детектим клик вне поля поиска
   */
  private detectClickOutside(e: Event): void {
    if (!this.showSearch) {
      return;
    }
    const path = e.composedPath();
    if (path.indexOf(this.$refs.searchBar as EventTarget) === -1) {
      this.toggleSearch();
    }
  }

  /**
   * Вызывается при вводе текства в input
   */
  public onChange(): void {
    this.showResults = !!this.query;
  }

  /**
   * Закрытие поиска при нажатии Esc
   */
  public onKeydown(e: KeyboardEvent): void {
    if (e.code === KeyCodes.Escape) {
      this.toggleSearch();
    }
  }
}

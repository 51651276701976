import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators';
import { ITeamsModule, TeamsModuleActions as Actions } from '@/store/modules/teams/ITeamsModule';
import { Teams } from '@/typings/domain';

import ApiClient from '@/api/api.client';
import store from '@/store';
import StoreModules from '@/store/StoreModules';

@Module({
  dynamic: true,
  name: StoreModules.Teams,
  namespaced: true,
  store,
})

class TeamsModule extends VuexModule implements ITeamsModule {
	teams: Teams[] = [];
  permissionStatus = false;
  isLastPage = false;
  id: number;
  teamsSpecialisation: any = [];

	@Mutation
	updateTeams(teams: Teams[]): void {
    this.teams = this.teams.concat(teams);
    this.teams = this.teams.filter((team, index, self) => {
      return self.findIndex(t => t.id === team.id) === index;
    });
  }
  
  @Mutation
  setTeams(teams: Teams[]): void {
    this.teams = teams;
	}

  @Mutation
	deleteTeammate(userId: number): void {
    this.teams = this.teams.filter((el) => el.id != userId);
	}

  @Mutation
	clearTeams(): void {
    this.teams = [];
	}
  @Mutation
	setStatus(status:boolean): void {
    this.permissionStatus = status;
	}

  @Mutation
	setTeamsSpecialisations(teamsSpecialisation:any): void {
    this.teamsSpecialisation = teamsSpecialisation;
	}

	/**
   * @Action fetchRoles
   */
  @Action
  async [Actions.fetchTeams](params?:any) {
    for(const key in params)
    {
      if(!params[key])
      {
        delete params[key]
      }
    }
    
    if(!this.isLastPage)
    {
      await ApiClient.teams.getAllTeams(params).then(data => {
        this.updateTeams(data);
      })
    }
  }

  @Action
  async [Actions.fetchTeamsSpecialisations](params?:any) {
    for(const key in params)
    {
      if(!params[key])
      {
        delete params[key]
      }
    }
    await ApiClient.teams.getSpecialisationsTeam().then(data => {
      this.setTeamsSpecialisations(data);
    })
  }
}

export default getModule(TeamsModule);
import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';
import SubCategoriesResponse from '@/models/dto/responses/subcategories.response';

const createSubCategories = async (name: string, parentId: number): Promise<SubCategoriesResponse> =>
  axios
    
    .post(`${MAIN_API_HOST}/subcategories`, 
    // eslint-disable-next-line
    {name, parent_id: parentId})
    .then(result => result.data);

export default createSubCategories;
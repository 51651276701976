import { Tag } from '@/typings/domain';

export interface ITagsModule {
  tags: Tag[];
}

export enum TagsModuleActions {
  fetchTags = 'fetchTags',
  setTagName = 'editTag',
  deleteTag = 'deleteTag',
  fetchKnowledgeTags = 'fetchKnowledgeTags',
  addNewTag = 'addNewTag',
  createTag = 'createTag',
}

import saveEditedUser from '@/api/user/actions/save-edited-user.action';
import get from '@/api/user/actions/get.action';
import getUserHistory from '@/api/user/actions/userHistory';
import makeAdmin from '@/api/user/actions/makeAdmin';
import recoverUser from '@/api/user/actions/recoverUser';
import deleteUser from '@/api/user/actions/deleteUser';
import completeRegistration from '@/api/user/actions/completeRegistration';
const user = {
  get,
  deleteUser,
  recoverUser,
  makeAdmin,
  saveEditedUser,
  getUserHistory,
  completeRegistration,
};

export default user;

import { Tag } from '@/typings/domain';

export interface ITagsGroupsModule {
    tagsGroups: Array<ITagsGroup>;
  }

export interface ITagsGroup {
    id: number;
    name: string;
    slug: string;
    tags: Tag[];
    created_at: string;
    updated_at: string;
}

export enum TagsGroupsModuleActions {
    fetchTagsGroups = 'fetchTagsGroups',
    createTagGroup = 'createTagGroup',
    setTagGroup = 'setTagGroup',
    deleteTagGroup = 'deleteTagGroup',
}

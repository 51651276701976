import setGeneralSettings from '@/api/settings/actions/setGeneralSettings';
import setGlobalSettings from '@/api/settings/actions/setGlobalSettings';
import getSettings from '@/api/settings/actions/getSettings';
import getOwner from '@/api/settings/actions/getOwner';
import fetchGeneralSettings from '@/api/settings/actions/fetchGeneralSettings';
const settings = {
    setGeneralSettings,
    fetchGeneralSettings,
    getSettings,
    getOwner,
    setGlobalSettings,
};

export default settings;
import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { Locales, Locale } from './model';
import { getInitialLocale } from './helpers';

Vue.use(VueI18n);

const loadedLanguages: Array<Locale> = [];

const i18n = new VueI18n({
  locale: getInitialLocale(),
  fallbackLocale: Locales.en,
  messages: {},
});

export const loadLocale = (locale: Locale) => {
  if (loadedLanguages.length > 0 && i18n.locale === locale) {
    return Promise.resolve(locale);
  }
  if (loadedLanguages.includes(locale)) {
    i18n.locale = locale;
    return Promise.resolve(locale);
  }
  /* eslint-disable-next-line */
  return import(/* webpackChunkName: "locale-[request]" */ `@/assets/i18n/${locale}.json`)
  .then((lang) => {
    i18n.setLocaleMessage(locale, lang.default);
    loadedLanguages.push(locale);
    i18n.locale = locale;
    return Promise.resolve(locale);
  });
};

export default i18n;
export * from './model';
export * from './options';
export { getInitialLocale } from './helpers';

import { CountedList, ISuccessResponse } from '@/api/types';
import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';
import { Specialisations } from '@/typings/domain';

const getAllSpecialisations = async (params?:object): Promise<Array<Specialisations>> =>
  axios.get(`${MAIN_API_HOST}/specialisations`, {
    params: params,
  }).then(result => {
    const payload = result.data.data;
    return payload
  });

export default getAllSpecialisations;

import { ISuccessResponse } from '@/api/types';
import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';

const checkHash = async (params: object): Promise<{success: boolean}> =>
  axios
    .get(`${MAIN_API_HOST}/hash/check`, {
      params: params,
    })
    .then(result => result.data);

export default checkHash;

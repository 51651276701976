import { ResponsesCategoryData } from '@/models/dto/responses/categories.response';
import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';

const createCategory = async (name: string, pinned: boolean): Promise<ResponsesCategoryData> =>
  axios
    .post(`${MAIN_API_HOST}/categories`,{
        name,
        pinned,
      })
    .then(result => result.data);

export default createCategory;
import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';

const restore = async (hash: string, password: string) => {
    return axios.post(`${MAIN_API_HOST}/password/restore`, {
        hash: hash,
        password: password,
    })
    .then(res => {
        return res;
    });
}

export default restore;
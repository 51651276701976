import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';

const reset = async (email: string): Promise<any> => {
    return axios.post(`${MAIN_API_HOST}/password/reset`, { email: email })
    .then(res => {
        return res;
    });
}

export default reset;
import { Knowledge } from '@/typings/domain';
import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';

const deleteSubcategory = async (id: number): Promise<string> =>
  axios
    .delete(`${MAIN_API_HOST}/subcategories/${id}`,{headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Credentials': 'true',
      }})
    .then(result => result.data);

export default deleteSubcategory;

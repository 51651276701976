import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';
// import UserInfo from '@/models/dto/responses/user.response.type';
import { UserDataInfo } from '@/typings/domain';

const completeRegistration = async (request:UserDataInfo) =>
{
  delete request.user_id
  return await axios.put(`${MAIN_API_HOST}/user/confirm`, request).then(result => result.data);
}
  

export default completeRegistration;

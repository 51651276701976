import { CountedList, ISuccessResponse } from '@/api/types';
import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';
import { Role } from '@/typings/domain';

const getAllRoles = async (params?:object): Promise<CountedList<Role>> =>
  axios.get(`${MAIN_API_HOST}/roles`, {params: params}).then(result => {
    const payload = result.data.data;
    return {
      list: payload,
      count: payload,
    };
  });

export default getAllRoles;

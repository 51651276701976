import { Route } from 'vue-router';
import ROUTES from '@/router/ROUTES';
import islogged from '@/api/auth/actions/islogged.action';
import Cookies from 'js-cookie';
import { HEADERS } from '@/services/helpers/constants';
import GeneralModule from '@/store/modules/general/GeneralModule';
import UsersModule from '@/store/modules/users/UsersModule';
import Notify from '@/services/helpers/notify';
import SettingsModule from '@/store/modules/settings/SettingsModule';

async function shouldBeAllowed(to: Route, from: Route, next: Function) {;
  const aToken = Cookies.get(HEADERS.AUTH_HEADER);
  if (!aToken || aToken.length === 0) {
    return next({ path: ROUTES.LOGIN });
  }
  await GeneralModule.me()
  const { confirmed } = await islogged() as unknown as {confirmed: boolean};

  GeneralModule.updateAuthCompleted(confirmed);
  if(confirmed && !SettingsModule.getSettings.length)
  {
    await SettingsModule.fetchSettings();
  }
  if(to.path.indexOf('/invite') > -1)
  {
    if(!UsersModule.currentUser.permitions.team.invite && !UsersModule.currentUser.admin)
    {
      Notify.error('Недостаточно прав')
      if(from.path === '/')
      {
        next({path: ROUTES.DOCUMENTS})
      }
      else
      {
        next({path: from.fullPath})
      }
    }
  }
  if(to.path.indexOf('/documents/create') > -1 || (to.params && to.params.documentId && to.hash === '#edit'))
  {
    if(!UsersModule.currentUser.permitions.knowledge.manage && !UsersModule.currentUser.admin)
    {
      Notify.error('Недостаточно прав')
      if(from.path === '/')
      {
        next({path: ROUTES.DOCUMENTS})
      }
      else
      {
        next({path: from.fullPath})
      }
    }
  }

  

  return next();
}

export default shouldBeAllowed;

import createSubCategories from '@/api/subcategories/actions/createSubcategories.action';
// import getSubCategory from '@/api/subcategories/actions/getSubcategory.action';
import getSubCategories from '@/api/subcategories/actions/getSubCategories.action';
import getSubCategoriesById from '@/api/subcategories/actions/getSubCategoriesById.action';
import deleteSubcategory from '@/api/subcategories/actions/deleteSubcategory.action';

const subCategories = {
    createSubCategories,
    // getSubCategory,
    getSubCategories,
    getSubCategoriesById,
    deleteSubcategory,
};

export default subCategories;

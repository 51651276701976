























import { Component, Vue, Watch } from 'vue-property-decorator';

import NavBar from '@/components/app-bar/NavBar.vue';
import NavBarMobile from '@/components/app-bar/NavBarMobile.vue';
import SearchBar from '@/components/app-bar/SearchBar.vue';
import PlusButton from '@/components/app-bar/PlusButton.vue';
import GeneralModule from '@/store/modules/general/GeneralModule';
import RolesModule from '@/store/modules/roles/RolesModule';
import TagsModule from '@/store/modules/tags/TagsModule';
import UsersModule from './store/modules/users/UsersModule';
import tagsGroups from '@/store/modules/tagsGroups/TagsGroupsModule';
import mode from '@/store/modules/mode/mode';
import SettingsModule from './store/modules/settings/SettingsModule';
@Component({
  name: 'App',
  components: {
    NavBar,
    NavBarMobile,
    SearchBar,
    PlusButton,
  },
})
export default class App extends Vue {
  private isLoading = false;

  get showNav() {
    return !this.$route.meta.withoutNavigation && this.$route.name;
  }

  get isAuthCompleted()
  {
    return GeneralModule.isAuthCompleted
  }

  get showLayout() {
    return !this.isLoading;
  }

  get documentTitle(): string {
    return this.$i18n.t(this.$route.name) as string;
  }

  get scrollLock(): boolean {
    return GeneralModule.scrollLock;
  }
  public isShadow = false;
  
  public async mounted(): Promise<void> {
    await GeneralModule.setupLanguage();

    if (GeneralModule.authToken) {
      this.isLoading = true;
      try {
        RolesModule.fetchRoles();

        if(GeneralModule.isAuthCompleted)
        {
          await TagsModule.fetchTags();
          await tagsGroups.fetchTagsGroups();
        }
      } finally {
        this.isLoading = false;
      }

      return;
    }
  }
  get shadowStatus()
  {
    return mode.globalShadow;
  }
  @Watch('shadowStatus')
  shadowStatusChange(status: boolean)
  {
    this.isShadow = status;
  }
  @Watch('documentTitle')
  onTitleChange(value: string): void {
    document.title = value;
  }

  @Watch('scrollLock')
  onScrollLockChange(value: boolean) {
    const bodyClassList = document.querySelector('html').classList;

    if (value) {
      bodyClassList.add('scroll-block');
    } else {
      bodyClassList.remove('scroll-block');
    }
  }
}

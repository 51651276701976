import createKnowledge from '@/api/knowledge/actions/create.action';
import updateKnowledge from '@/api/knowledge/actions/update.action';
import getKnowles from '@/api/knowledge/actions/get.action';
import { getKnowledge } from '@/api/knowledge/actions/get.action';
import deleteKnowledge from '@/api/knowledge/actions/delete.action';
import setAsViewed from '@/api/knowledge/actions/setAsViewed.action';
import setProjectSettings from '@/api/knowledge/actions/setKnowledgeSettings.action'

const knowledge = {
  createKnowledge,
  updateKnowledge,
  getKnowles,
  getKnowledge,
  deleteKnowledge,
  setAsViewed,
  setProjectSettings,
};

export default knowledge;

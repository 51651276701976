import Toastr from 'toastr2';

import 'toastr2/dist/toastr.min.css';

const toastr = new Toastr();
toastr.options.closeButton = true;
toastr.options.closeDuration = 300;
toastr.options.hideEasing = 'swing';
toastr.options.preventDuplicates = true;

class Notify {
  public static info(message = 'Info'): void {
    toastr.info(message);
  }

  public static error(message = 'Error. Try again later'): void {
    toastr.error(message);
  }

  public static success(message = 'Success!'): void {
    toastr.success(message);
  }
}
export default Notify;
import AuthRequest from '@/models/dto/auth.request';
import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';

type AuthResponse = {
  token: string;
};

const login = async (request: AuthRequest): Promise<AuthResponse> =>
  axios.post(`${MAIN_API_HOST}/auth`, request.toJson()).then(result => result.data);

export default login;

import { getModule, Module, VuexModule } from 'vuex-module-decorators';
import { IMenuModule } from '@/store/modules/menu/IMenuModule';
import { IContextMenuItem, ContextMenuItemType } from '@/components/context-menu';
import StoreModules from '@/store/StoreModules';
import store from '@/store';
import router from '@/router';
import GeneralModule from '@/store/modules/general/GeneralModule';
import i18n from '@/services/i18n';
import UsersModule from '../users/UsersModule';

@Module({
  dynamic: true,
  name: StoreModules.Menu,
  namespaced: true,
  store,
})
class MenuModule extends VuexModule implements IMenuModule {
  mainMenu = [
    {
      text: 'Menu.Tasks',
      icon: 'iconly/ticksquare',
      link: '/tasks',
    },
    {
      text: 'Menu.Projects',
      icon: 'iconly/work',
      link: '/projects?direction=clients',
    },
    {
      text: 'Menu.Documents',
      icon: 'iconly/document',
      link: '/documents',
    },
    {
      text: 'Menu.Marks',
      icon: 'iconly/chart',
      link: '/marks',
    },
    {
      text: 'Menu.Team',
      icon: 'iconly/2user',
      link: '/team',
    },
    {
      text: 'Menu.Settings',
      icon: 'iconly/home',
      link: '/settings',
    },
  ];

  reducedMenu = [
    {
      text: 'Menu.Documents',
      icon: 'iconly/document',
      link: '/documents',
    },
    {
      text: 'Menu.Team',
      icon: 'iconly/2user',
      link: '/team',
    },
    // {
    //   text: 'Menu.Projects',
    //   icon: 'iconly/2user',
    //   link: '/projects?direction=clients',
    // },
  ];

  get userId() {
    return UsersModule.currentUserId;
  }

  public get userMenu() {
    return [
      {
        text: i18n.tc('General.Profile'),
        icon: 'iconly/profile',
        link: `/team/user/${this.userId}`,
      },
      {
        text: i18n.tc('General.AccountSettings'),
        icon: 'iconly/setting',
        link: '/settings',
      },
      {
        text: i18n.tc('General.SignOut'),
        icon: 'iconly/logout',
        action: () => GeneralModule.logout(),
      },
    ];
  }

  public get widgetUserMenu(): Array<IContextMenuItem> {
    return [
      {
        text: 'username',
        type: ContextMenuItemType.header,
      },
      {
        type: ContextMenuItemType.divider,
      },
      {
        type: ContextMenuItemType.link,
        text: i18n.tc('General.Profile'),
        action: () => {
          router.push(`/team/user/${UsersModule.currentUserId}`);
        },
      },
      {
        type: ContextMenuItemType.link,
        text: i18n.tc('General.Settings'),
        action: () => router.push('/settings'),
      },
      {
        type: ContextMenuItemType.divider,
      },
      {
        type: ContextMenuItemType.link,
        text: i18n.tc('General.SignOut'),
        action: () => GeneralModule.logout(),
      },
    ];
  }
}

export default getModule(MenuModule);

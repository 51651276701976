import { ISuccessResponse } from '@/api/types';
import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';

const send = async (params: any): Promise<ISuccessResponse<boolean>> =>
  axios
    .post(`${MAIN_API_HOST}/user/invite`, {
      users: params,
    })
    .then(result => result.data);

export default send;

import getAllSpecialisations from '@/api/specialisations/actions/getAllSpecialisations';
import createSpecialisation from './actions/createSpecialisation';
import updateSpecialisations from './actions/updateSpecialisations';
const specialisations = {
    createSpecialisation,
    getAllSpecialisations,
    updateSpecialisations,
};

export default specialisations;

import { RouteConfig } from 'vue-router';

import ROUTES from '@/router/ROUTES';
import { DocumentModes } from './components/document/model';
import shouldBeAuthenticated from '@/router/middlewares/shouldBeAuthenticated';
import shouldBeAllowed from '@/router/middlewares/shouldBeAllowed';
import shouldBeSectionsEnabled from '@/router/middlewares/shouldBeSectionsEnabled';

export const documentsRoutes: Array<RouteConfig> = [
  {
    path: ROUTES.DOCUMENTS,
    component: () => import(/* webpackChunkName: "documents-module" */ './Documents.vue'),
    beforeEnter: shouldBeAuthenticated,
    children: [
      {
        path: '',
        redirect: 'dashboard',
      },
      {
        path: 'dashboard',
        component: () =>
          import(/* webpackChunkName: "documents-module" */ './components/documents-dashboard/DocumentsDashboard.vue'),
        name: 'Documents Dashboard',
        beforeEnter: shouldBeSectionsEnabled,
      },
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "documents-module" */ './components/document/Document.vue'),
        name: 'Create document',
        props: {
          mode: DocumentModes.Create,
        },
        beforeEnter: shouldBeAllowed,
      },
      {
        path: 'project/:projectId',
        component: () => import(/* webpackChunkName: "documents-module" */ './components/document/Document.vue'),
        name: 'View document',
        props: {
          mode: DocumentModes.Create,
        },
      },
      {
        path: ':documentId',
        component: () => import(/* webpackChunkName: "documents-module" */ './components/document/Document.vue'),
        name: 'Document',
        beforeEnter: shouldBeAllowed,
      },
      
    ],
  },
];

import { IGeneralModule } from '@/store/modules/general/IGeneralModule';
import { getInitialLocale, Locales, Locale, loadLocale } from '@/services/i18n';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import StoreModules from '@/store/StoreModules';
import store from '@/store';
import { HEADERS } from '@/services/helpers/constants';
import SignupRequest from '@/models/dto/signup.request';
import ApiClient from '@/api/api.client';
import AuthRequest from '@/models/dto/auth.request';
import { InviteData } from '@/models/invite';
import UsersModule from '../users/UsersModule';
import Cookies from 'js-cookie';
import router from '@/router';
import logoutAction from '@/api/auth/actions/logout.action';
import ROUTES from '@/router/ROUTES';
import Notify from '@/services/helpers/notify';
import i18n from '@/services/i18n';
import TagsModule from '@/store/modules/tags/TagsModule';
import TagsGroupsModule from '@/store/modules/tagsGroups/TagsGroupsModule';
import SpecializationsModule from '../specializations/SpecializationsModule';
import TeamsModule from '../teams/TeamsModule';
import KnowledgeModule from '../knowledge/KnowledgeModule';

@Module({
  dynamic: true,
  name: StoreModules.General,
  namespaced: true,
  store,
})
class GeneralModule extends VuexModule implements IGeneralModule {
  authToken: string;
  appLoading = false;
  authCompleted = false;
  appLocale = getInitialLocale();
  localesList = [
    { title: 'Русский', code: Locales.ru, value: 'ru' },
    { title: 'English', code: Locales.en, value: 'en' },
  ];
  countryList = [
    { title: 'Россия', code: 'RU', value: 'RU' },
    { title: 'USA', code: 'USA', value: 'USA' },
  ];
  signupRequest: SignupRequest;
  scrollBlockers: Set<string> = new Set();
  popupContextHeight = 0;
  constructor(props: unknown) {
    super(props);

    this.authToken = Cookies.get(HEADERS.AUTH_HEADER);
  }

  get scrollLock(): boolean {
    return 0 < this.scrollBlockers.size;
  }

  get isAuthCompleted()
  {
    return this.authCompleted;
  }
  get popupHeight()
  {
    return this.popupContextHeight;
  }

  @Mutation
  updateAuthCompleted(status: boolean)
  {
    this.authCompleted = status;
  }

  @Mutation
  setPopupContextHeight(value: number)
  {
    this.popupContextHeight = value;
  }
  
  @Mutation
  public setLoadingAndLocale(payload: { loading: boolean; locale: Locale }) {
    const { loading, locale } = payload;

    if (loading !== null) {
      this.appLoading = loading;
    }

    if (locale !== null) {
      this.appLocale = locale;
    }
  }


  @Mutation
  public setAuthToken(token: string) {
    this.authToken = token;
    Cookies.set(HEADERS.AUTH_HEADER, token);
  }

  @Mutation
  public async logout() {
    if (!this.authToken || this.authToken.length === 0) {
      Cookies.remove(HEADERS.AUTH_HEADER);
      return router.push({ path: ROUTES.LOGIN });
    }

    const success = await logoutAction();

    if (!success) {
      return Notify.error(i18n.t('Errors.Bad_logout').toString());
    }

    Cookies.remove(HEADERS.AUTH_HEADER);

    return router.push({ path: ROUTES.LOGIN });
  }


  @Mutation
  updateScrollBlockers(newScrollBlockers: Set<string>) {
    this.scrollBlockers = new Set(newScrollBlockers);
  }

  @Action
  public addScrollBlocker(id: string) {
    const newScrollBlockers = this.scrollBlockers;
    newScrollBlockers.add(id);
    this.updateScrollBlockers(newScrollBlockers);
  }

  @Action
  public removeScrollBlocker(id: string) {
    const newScrollBlockers = this.scrollBlockers;
    newScrollBlockers.delete(id);
    this.updateScrollBlockers(newScrollBlockers);
  }

  @Action
  public async setupLanguage(locale?: Locale) {
    if (!locale) {
      locale = this.appLocale;
    }

    this.setLoadingAndLocale({
      loading: true,
      locale: null,
    });
    try {
      await loadLocale(locale);
      this.setLoadingAndLocale({
        loading: true,
        locale: locale,
      });
    } catch (e) {
      console.error(e);
      this.setLoadingAndLocale({
        loading: false,
        locale: null,
      });
    }
  }

  @Action
  public async changeLanguage(locale: Locale) {
    if (locale === this.appLocale) {
      return;
    }

    await this.setupLanguage(locale);
  }


  @Action({ rawError: true })
  public async signup() {
    const user = await ApiClient.auth.signup(this.signupRequest);
    this.setAuthToken(user.token);
    UsersModule.setUser({ id: user.id, user });
    UsersModule.setCurrentUser(user.id);

    await this.setupLanguage();
  }

  @Action
  public async login(request: AuthRequest) {
    try {
      const token = (await ApiClient.auth.login(request)).token;
      this.setAuthToken(token);
      const user = await ApiClient.auth.me();
      UsersModule.setUser({ id: user.id, user });
      UsersModule.setCurrentUser(user.id);

      await this.setupLanguage();
      // await TagsModule.fetchTags();
      // await TagsGroupsModule.fetchTagsGroups();

      return true;
    } catch (e) {
      return false;
    }
  }

  @Action
  public async me() {
    const user = await ApiClient.auth.me();
    UsersModule.setUser({ id: user.id, user });
    UsersModule.setCurrentUser(user.id);
  }

  @Action
  public async invitePeoples(invites: InviteData[]) {
    if (invites.length < 1) {
      return;
    }
    await ApiClient.invite.send(invites);
  }
  @Action
  public async checkHash(hash: string) 
  {
    return await ApiClient.invite.checkHash(
      {
        hash,
      },
    );
  }
}

export default getModule(GeneralModule);

import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';

const createSpecialisation = async (specialisationName:string, role_id: number): Promise<any> =>
  axios.post(`${MAIN_API_HOST}/specialisations`, {
    name: specialisationName,
    role_id: role_id,
  }).then(result => {
    const payload = result.data.data;
    return payload
  });

export default createSpecialisation;

import getTagsGroups from './actions/getTagsGroups.action';
import createTagGroup from './actions/createTagGroup.action';
import setTagGroup from './actions/setTagGroup.action';
import deleteTagGroup from './actions/deleteTagGroup.action';


const tagsGroups = {
    getTagsGroups,
    createTagGroup,
    setTagGroup,
    deleteTagGroup,
};

export default tagsGroups;

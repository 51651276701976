import { SubRole } from '@/typings/domain';
import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';

const createSubRole = async (parentRoleId: number, name: string): Promise<SubRole> =>
  axios
    .post(`${MAIN_API_HOST}/role/${parentRoleId}/sub-role`, {
      name,
    })
    .then(result => result.data);

export default createSubRole;

import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators';
import { ISettingsModule, SettingsModuleActions as Actions } from '@/store/modules/settings/ISettingsModule';
import { Settings } from '@/typings/domain';

import ApiClient from '@/api/api.client';
import store from '@/store';
import StoreModules from '@/store/StoreModules';

@Module({
    dynamic: true,
    name: StoreModules.Settings,
    namespaced: true,
    store,
})

class SettingsModule extends VuexModule implements ISettingsModule {
    sectionSettings: Settings[] = [];
    sectionGeneralSettings: Settings[] = [];
    

    get getSettings()
    {
        return this.sectionSettings;
    }
    get getGeneralSettings()
    {
        return this.sectionGeneralSettings;
    }

    @Mutation setSettings(settings: Settings[])
    {
        this.sectionSettings = settings;
    }

    @Mutation setGeneralSettings(settings: Settings[])
    {
        this.sectionGeneralSettings = settings;
    }

    @Action
    getSettingsSectionBySlug(slug: string)
    {
        return this.getSettings.filter((el) => el.slug === slug)[0];
    }

    @Action
    async [Actions.fetchSettings]() 
    {
        await ApiClient.settings.getSettings().then((data) => 
        {
            const settings = data.data
            this.setSettings(settings)
        })
    }
    @Action
    async [Actions.fetchGeneralSettings]() 
    {
        await ApiClient.settings.fetchGeneralSettings().then((data) => 
        {
            const settings = data.data
            this.setGeneralSettings(settings)
        })
    }
}

export default getModule(SettingsModule);
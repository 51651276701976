import { IGetResponse } from '@/api/types';
import { Knowledge, KnowledgeCreatePayload } from '@/typings/domain';
import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';

const createKnowledge = async (payload: KnowledgeCreatePayload): Promise<IGetResponse<Knowledge>> => {
  if (!payload.subcategory_id)
    delete payload.subcategory_id;

  return axios.post(`${MAIN_API_HOST}/knowledge/`, payload)
    .then(result => result.data);
  }
export default createKnowledge;

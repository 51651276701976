import getAllTeams from '@/api/teams/actions/getAllTeams';
import changePassword from './actions/changePassword';
import getSpecialisationsTeam from './actions/getSpecialisationsTeam';

const teams = {
  getAllTeams,
  getSpecialisationsTeam,
  changePassword,
};

export default teams;

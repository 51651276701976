import { Teams } from '@/typings/domain';

export interface ITeamsModule {
  teams: Teams[];
}

export enum TeamsModuleActions {
  fetchTeams = 'fetchTeams',
  fetchTeamsSpecialisations = 'fetchTeamsSpecialisations',
}

import { IGetResponse } from '@/api/types';
import { Knowledge } from '@/typings/domain';
import { KnowledgeSetAsViewedResponse } from '@/models/dto/responses/knowledgeResponse';
import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';

const setAsViewed = async (documentId: number): Promise<KnowledgeSetAsViewedResponse> => {
  

  return axios.post(`${MAIN_API_HOST}/knowledge/setAsViewed`, {
  	knowledge_id: documentId,
  })
    .then(result => result.data);
  }
export default setAsViewed;

import { CountedList, ISuccessResponse } from '@/api/types';
import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';
import { Tag } from '@/typings/domain';

const getAllTags = async (): Promise<CountedList<Tag>> =>
  axios.get(`${MAIN_API_HOST}/tags`).then(result => {
    const payload = result.data.data;
    return {
      list: payload,
      count: payload,
    };
  });

export default getAllTags;

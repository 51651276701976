import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ClassListComposer extends Vue {
  classGetters: Array<Function> = [];

  public get composedClassList(): Array<string> {
    const classList: Array<string> = [];

    this.classGetters.forEach(getter => {
      const className: string = getter();

      if (className !== null) {
        classList.push(className);
      }
    });

    return classList;
  }
}

import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';
import SubCategoriesResponse from '@/models/dto/responses/subcategories.response';

const getSubCategories = async (id: number): Promise<SubCategoriesResponse> =>
  axios
    .get(`${MAIN_API_HOST}/subcategories`, {
      params: {
        id: id,
      },
    })
    .then(result => result.data);

export default getSubCategories;
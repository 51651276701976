import { Component, Vue, Prop } from 'vue-property-decorator';
import { DefaultData } from 'vue/types/options';
import { ComponentOptions } from 'vue/types/umd';

@Component
export default class Colorizable extends Vue {
  baseClass = 'colorizable';
  classGetters: Array<Function> = [];
  $parent: Vue & Colorizable;
  $options: ComponentOptions<Vue, DefaultData<Vue>> & {
    propsData: { [key: string]: string };
  };

  @Prop() color: string;

  created() {
    this.classGetters.push(this.colorClassName);
  }

  public colorClassName(): string {
    if (this.$options.propsData?.color) return `${this.baseClass}--color-${this.color}`;
    if (this.$parent.color) return `${this.baseClass}--color-${this.$parent.color}`;
    if (this.color) return `${this.baseClass}--color-${this.color}`;
    return null;
  }
}

import axios from 'axios';
import { CategoryUpdatePayload } from '@/typings/domain';
import { Categories } from '@/typings/categories/categories';
import { MAIN_API_HOST } from '@/services/helpers/constants';

const updateCategory = async (id: number, payload: CategoryUpdatePayload): Promise<Categories> =>
  axios
    .put(`${MAIN_API_HOST}/categories/${id}`, payload)
    .then(result => result.data);

export default updateCategory;
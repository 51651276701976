import { Route } from 'vue-router';
import ROUTES from '@/router/ROUTES';
import islogged from '@/api/auth/actions/islogged.action';
import Cookies from 'js-cookie';
import { HEADERS } from '@/services/helpers/constants';
import UsersModule from '@/store/modules/users/UsersModule';
import router from '..';
import Notify from '@/services/helpers/notify';
import GeneralModule from '@/store/modules/general/GeneralModule';
import SettingsModule from '@/store/modules/settings/SettingsModule';
async function shouldBeAdmin(to: Route, from: Route, next: Function) {
    const aToken = Cookies.get(HEADERS.AUTH_HEADER);

    const { auth, confirmed } = await islogged() as undefined as { auth: boolean, confirmed: boolean };

    if (!auth) 
    {
        Cookies.remove(HEADERS.AUTH_HEADER);
        return next({ path: ROUTES.LOGIN });
    }
    await GeneralModule.me()
    GeneralModule.updateAuthCompleted(confirmed);
    console.log('few2')
    if(confirmed && !SettingsModule.getSettings.length)
    {
        await SettingsModule.fetchSettings();
    }
    if(to.params.id)
    {
        const id = await Number(to.params.id)
        await UsersModule.fetchUser(id)
        if (!aToken || aToken.length === 0) 
        {
            return next({ path: ROUTES.LOGIN });
        }
        if ((await UsersModule.currentUser.id != id) && !await UsersModule.currentUser.admin) {
            Notify.error('Недостаточно прав')
            return next(router.push(`/team/user/${id}`));
        }
    }
    else if(!UsersModule.currentUser.admin)
    {
        Notify.error('Недостаточно прав')
        return next(router.push(from.path).catch((e:any) => e));
    }
    return next();
}

export default shouldBeAdmin;

import setTeamsSettings from '@/api/settingsTeams/actions/setTeamsSettings';
import createField from '@/api/settingsTeams/actions/createField';
import deleteField from '@/api/settingsTeams/actions/deleteFields';
import editField from '@/api/settingsTeams/actions/editField';
import getFields from '@/api/settingsTeams/actions/getFields';
import setFieldStatus from '@/api/settingsTeams/actions/setFieldStatus';
const settingsTeams = {
    setTeamsSettings,
    getFields,
    editField,
    deleteField,
    createField,
    setFieldStatus,
};

export default settingsTeams;
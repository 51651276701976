import UserInfo from '@/models/dto/responses/user.response.type';

export interface IUsersModule {
  users: Map<number, UserInfo>;
}

export enum UsersModuleActions {
  updateUser = 'updateUser',
  addSpecialization = 'addSpecialization',
  completeRegistration = 'completeRegistration',
  removeSpecialization = 'removeSpecialization',
  removeRole = 'removeRole',
  updatePassword = 'updatePassword',
  fetchUser = 'fetchUser',
  fetchOwner = 'fetchOwner',
  updateUserProfile = 'updateUserProfile',
}

import { Specialisations } from '@/typings/domain';

export interface ISpecialisationsModule {
    specialisations: Specialisations[];
}

export enum SpecialisationsModuleActions {
  fetchSpecialisations = 'fetchSpecialisations',
  updateSpecialisations = 'updateSpecialisations',
}

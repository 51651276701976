import { CountedList, ISuccessResponse } from '@/api/types';
import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';
import { ClientsResponse } from '@/models/dto/responses/clients.response';

const getAllProjects = async (payload:any): Promise<ClientsResponse[]> =>
  axios.get(`${MAIN_API_HOST}/projects/`, {
    params: payload,
  }).then((response) => response.data.data);

export default getAllProjects;
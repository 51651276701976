import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators';
import { ITagsModule, TagsModuleActions as Actions } from '@/store/modules/tags/ITagsModule';
import { Tag } from '@/typings/domain';
import { TagsParams } from '@/typings/domain';

import ApiClient from '@/api/api.client';
import store from '@/store';
import StoreModules from '@/store/StoreModules';

@Module({
    dynamic: true,
    name: StoreModules.Tags,
    namespaced: true,
    store,
})

class TagsModule extends VuexModule implements ITagsModule {
    tags: Tag[] = [];
            
    @Mutation
    setTags(tags: Tag[]): void {
        this.tags = tags;
    }

    @Mutation
    setKnowledgeTags(tags: Tag[]): void {
        this.tags = tags;
    }

    /**
     * @Action fetchRoles
     */
    @Action
    async [Actions.fetchTags]() {
        await ApiClient.tags.getAllTags().then(rolesFromServer => {
            this.setTags(rolesFromServer.list);
        });
    }

    /**
     * @Action fetchRoles
     */
    @Action
    async [Actions.fetchKnowledgeTags](params?: TagsParams) {
        await ApiClient.tags.fetchKnowledgeTags(params).then(data => {
            this.setKnowledgeTags(data.data)
        })
        .catch((e) => 
        {
            return e
        })
    }

    /**
     * @Action setTagName
     */
    @Action
    async [Actions.setTagName]({ id, newName }: { id: number; newName: string }) {
        await ApiClient.tags.setTagName(id, newName);
    }

    /**
     * @Action deleteTag
     */
    @Action
    async [Actions.deleteTag](id: number) {
        await ApiClient.tags.deleteTag(id);
    }

    /**
     * @Action createTag
     */
    @Action
    async [Actions.createTag](newTag: {name: string, color: string}) {
        await ApiClient.tags.createTag(newTag);
    }
}

export default getModule(TagsModule);

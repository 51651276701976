import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';
import { Tag } from '@/typings/domain';
import { TagsParams } from '@/typings/domain';

interface TagsResponse 
{
  data: Tag[],
  links: any,
  meta: any,
}
const getKnowledgeTags = async (params?: TagsParams): Promise<TagsResponse> =>
  axios.get(`${MAIN_API_HOST}/tags`, {params}).then(result => {
    const response = result.data;
    return response;
  });

export default getKnowledgeTags;

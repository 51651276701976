import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators';
import {
    ITagsGroup,
    ITagsGroupsModule,
    TagsGroupsModuleActions as Actions,
} from '@/store/modules/tagsGroups/ITagsGroupsModule';

import ApiClient from '@/api/api.client';
import store from '@/store';
import StoreModules from '@/store/StoreModules';

@Module({
    dynamic: true,
    name: StoreModules.TagsGroups,
    namespaced: true,
    store,
})
class TagsGroupsModule extends VuexModule implements ITagsGroupsModule {
    tagsGroups: Array<ITagsGroup> = [];

    @Mutation
    setTagsGroups(tagsGroups: Array<ITagsGroup>): void {
        this.tagsGroups = tagsGroups;
    }

    /**
     * @Action fetchTagsGroups
     */
    @Action
    async [Actions.fetchTagsGroups]() {
        await ApiClient.tagsGroups.getTagsGroups().then(groupsFromServer => {
            this.setTagsGroups(groupsFromServer);
        });
    }

    /**
     * @Action createTagGroup
     */

    @Action
    async [Actions.createTagGroup]({ newName, tags }: { newName: string; tags: number[] }) {
        await ApiClient.tagsGroups.createTagGroup(newName, tags);
    }

    /**
     * @Action setTagGroup
     */

    @Action
    async [Actions.setTagGroup]({ id, newName, tags }: { id: number; newName: string; tags: number[] }) {
        await ApiClient.tagsGroups.setTagGroup(id, newName, tags);
    }

    /**
     * @Action deleteTag
     */
    @Action
    async [Actions.deleteTagGroup](id: number) {
        await ApiClient.tagsGroups.deleteTagGroup(id);
    }
}

export default getModule(TagsGroupsModule);

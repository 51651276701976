import 'vue-class-component/hooks'; // import hooks type to enable auto-complete
import Vue from 'vue';
import VueMq from 'vue-mq';
import Fragment from 'vue-fragment';
import vClickOutside from 'v-click-outside';

import App from './App.vue';
import router from './router';
import i18n from './services/i18n';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import 'normalize.css/normalize.css';
import { configureHttpClient } from '@/api/_axios';
import store from '@/store';
Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper)
Vue.use(VueMq, {
  breakpoints: {
    // Изменяя брейкпоинты здесь, незабуть поменять их в _variables.scss
    xs: 425,
    sm: 768,
    md: 1024,
    lg: 1200,
    xl: Infinity,
  },
  defaultBreakpoint: 'lg',
});

Vue.use(Fragment.Plugin);
Vue.use(vClickOutside);

Vue.mixin({
  computed: {
    $isMobile(): boolean {
      return /(xs|sm)/.test(this.$mq as string);
    },
    $isTablet(): boolean {
      return /(md)/.test(this.$mq as string);
    },
    $isDesktop(): boolean {
      return /(lg|xl)/.test(this.$mq as string);
    },
  },
});

configureHttpClient();

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
